var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "card cardContentImage",
      style: { order: _vm.content.order }
    },
    [
      _c(
        "div",
        { staticClass: "row" },
        [
          _c(
            "div",
            {
              staticClass: "col-md-6 col-sm-6 col-xs-6 ",
              class: _vm.content.imagesOrientation
            },
            [
              _c(
                "div",
                { ref: "leftcol" },
                _vm._l(_vm.images, function(image, index) {
                  return _c("image-details", {
                    key: index,
                    attrs: { imageDetails: image, "default-width": 304 }
                  })
                })
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass: "column col-md-6 col-sm-6 col-xs-6",
              style: { "align-self": _vm.content.alignment }
            },
            [
              _c(
                "div",
                { ref: "cardHeader", staticClass: "card-header row" },
                [
                  _c(
                    "span",
                    {
                      staticClass: "card-title",
                      class: { titleAudio: _vm.content.audio }
                    },
                    [_vm._v(_vm._s(_vm.content.title))]
                  ),
                  _vm.content.audio
                    ? _c("AudioToggle", {
                        attrs: {
                          "white-background": true,
                          audio: _vm.content.audio
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "body",
                  style: { height: _vm.maxHeightContent + "px" }
                },
                [
                  _c("div", { staticClass: "card-overflow h-100" }, [
                    _c("span", {
                      domProps: { innerHTML: _vm._s(_vm.textContent) },
                      on: { click: _vm.GoToLink }
                    })
                  ])
                ]
              )
            ]
          ),
          _vm.showModal
            ? _c(
                "modal",
                {
                  attrs: {
                    "text-button": _vm.$t("done"),
                    "click-handler": _vm.hideVocabularyBox,
                    clickOutSideModalHandler: _vm.hideVocabularyBox,
                    showCancelButton: _vm.showModalCancelButton,
                    modalType: _vm.modalType
                  }
                },
                [
                  _c("vocabulary-box", {
                    attrs: { vocabulary: _vm.selectedWord }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }