<template>
    <div  class="disclaimer row">
        <span v-tooltip="disclaimer"  >{{disclaimer}}</span>
    </div>
</template>
<script>
export default {
    name: 'Disclaimer', 
    props:{
        disclaimer: String
    }
}
</script>
<style lang="less" scoped>
.disclaimer{
	min-height: 32px;
	width: 100%;
    background-color: #E0E0E0;
	color: #0A1554;
	font-family: 'Roboto';
	font-size: 12px;
	font-style: italic;
	letter-spacing: 0.4px;
    line-height: 16px;
    margin-left: 0 !important;

    span{
        align-self: center;
        margin-left: 16px;
    }
}
</style>

