<template>
  <div class="global_connection global-connection-modal-box">
    <div class="global_connection_top">
      <div class="global_connection_left">
        <img :src="gcImage" />
      </div>
      <div class="global_connection_right">
        <img :src="gcMap" />
      </div>
    </div>
    <div class="global_connection_bottom">
      <div class="global_connection_left">
        <img :src="imageTitle" />
        <disclaimer
          class="global_connection_left_desc"
          :disclaimer="getGcText(5)"
        />
        <div class="textBox">
          <div class="global_connection_left_audio">
            <audio-toggle :audio="getGcAudio(3)" :white-background="true" />
          </div>
          <p class="custom-scrollbar desc-text" v-html="getGcText(3)"></p>
        </div>
      </div>
      <div class="global_connection_right">
        <div class="global_audio">
          <audio-toggle :audio="getGcAudio(4)" />
        </div>
        <p class="custom-scrollbar main-text overflow-clip l-height" v-html="getGcText(4)"></p>
      </div>
    </div>
  </div>
</template>
<script>
import AudioToggle from "@/common/AudioToggle.vue";
import Disclaimer from "@/common/Disclaimer.vue";
export default {
  name: "GlobalConnectionsPreviewData",
  props: ["routePoints"],
  data() {
    return {};
  },
  components: {
    AudioToggle,
    Disclaimer,
  },
  computed: {
    gcImage() {
      return this.getImageLink(1);
    },
    gcMap() {
      return this.getImageLink(2);
    },
    imageTitle() {
      return this.getImageLink(3);
    },
    globalImageOptions() {
      return {
        name: "image-upload",
        text: "addImageText",
        model: "imageId",
      };
    },
  },

  methods: {
    getRoutePoint(index) {
      let route;
      this.routePoints.forEach((i) => {
        if (i.number === index) route = i;
        return;
      });
      return route;
      // return this.content.routePoints[index];
    },

    getImageLink(index) {
      let routePoint = this.getRoutePoint(index);
      let backgroundImageName = null;
      if (routePoint) backgroundImageName = routePoint.images[0];
      return this.getImageUrl(backgroundImageName);
    },
    getGcText(index) {
      let routePoint = this.getRoutePoint(index);
      let gcText = null;
      if (routePoint) {
        gcText = routePoint.content;
      }
      return gcText;
    },
    getGcAudio(index) {
      let routePoint = this.getRoutePoint(index);
      let gcAudio = null;
      if (routePoint) gcAudio = routePoint.audio;
      return gcAudio;
    },
  },
};
</script>
<style lang="less" scoped>
.desc-text {
  overflow: auto;
  height: 68px !important;
  font-size: 13px !important;
}
.main-text {
  overflow: auto;
  font-size: 15px !important;
  margin-top: -27px !important;
}
.modal-container {
  padding-bottom: 24px;
}
.global_connection {
  display: flex;
  flex: 1;
  flex-direction: column;
  // padding: 10px;
  padding-left: 26px;
  padding-bottom: 20px;
  margin-right: 30px;
  z-index: 2;


  .global_connection_top {
    display: flex;
    max-height: 172px;
    margin-bottom: 10px;
    margin-top:24px;

    .global_connection_left {
      width: 40%;
      display: flex;
      justify-content: center;
      // flex-direction: column;
      margin-right: 10px;
      // min-height: 10rem;

      > img {
        // min-height: 192px;
        max-width: 100%;
        max-height: 100%;
        // height: 28vh;
        object-fit: scale-down;
      }
      > p {
        max-width: 250px;
        max-height: 300px;
      }
    }
    .global_connection_right {
      width: calc(60% - 10px);
      display: flex;
      // min-height: 10rem;
      > img {
        max-width: 100%;
        max-height: 100%;
        margin: auto;
        // min-height: 192px;
      }
    }
  }
  .global_connection_left_desc {
    margin-right: 0;
    width: 100%;
    > p {
      margin-top: -10px;
    }
  }
  .global_connection_bottom {
    overflow: hidden;
    display: flex;
    flex: 1;
    max-height: 18rem;

    .global_connection_left {
      
      max-width: 40%;
      // min-height: 278px;
      margin-right: 10px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: white;
      .disclaimer {
        font-size: 9px;
        line-height: 14px;
        min-height: auto;
        position: relative;
        z-index: 1;
      }
      .textBox {
        width: 100%;
        position: relative;
        > .global_connection_left_audio {
          display: flex;
          align-items: center;
          flex: 0.01;
          margin-bottom: -89px;
          position: absolute;
          left: 5px;
          top: 0;
          z-index: 2;
        }
        > .custom-scrollbar {
          padding-left: 30px;
          margin-top: 8px !important;
          // max-height: 52px;
        }
        > p {
          // width: 302px;
          height: 8.5vh;
          background-color: white;
          padding: 0 7px 7px 7px;
          // margin-top: -10px;
        }
        > p {
          // overflow: hidden;
          margin-top: 0 !important;
          line-height: 16px !important;
          // font-size: 13px !important;
          color: #000 !important;
        }
      }

      > img {
        // width: 320px;
        max-width: 100%;
        max-height: 232px;
        // margin-bottom: -10px;
      }
    }
    .global_connection_left_desclimer {
      > p {
        margin-top: -10px;
        overflow-y: scroll;
      }
    }
    .global_connection_right {
      width: calc(60% - 10px);
      // flex:0.78;
      background-color: white;
      // min-height: 278px;

      > h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-top: 0 !important;
        // color:#3A4DFD !important;
      }
      > p {
        // height: 38vh;
        margin-left: 20px;
        // margin-top: -12px;
        line-height: 16px !important;
        // font-size: 13px !important;
        color: #000 !important;
        padding-right: 40px;
        font-size: 16px !important;
      }

      .global_audio {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: 15px;
        margin-top: 16px;
      }
      .global_audio {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
}
</style>
<style lang="less">
  .global_connection_bottom {
    .global_connection_left {
      margin-left: auto;
      .textBox {
        p {
            line-height: 16px !important;
            font-size: 13px !important;
          p{
            line-height: 16px !important;
            font-size: 13px !important;
          }
        }
      }
    }
    .global_connection_right {
      margin-left: auto;
      .main-text {
          font-size: 16px !important;
          line-height: 18px !important;
        p{
          font-size: 16px !important;
          line-height: 18px !important;
        }
      }
      .global_audio{
        i >img{
          margin-top: -16px;
        }
      }
    }
  }
</style>
