var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lesson-content" },
    [
      _c(
        "div",
        { staticClass: "body row" },
        [
          _c("span", { style: { opacity: _vm.displayNumber ? 1 : 0 } }, [
            _vm._v(_vm._s(_vm.content.number))
          ]),
          _c("p", {
            staticClass: "map-paragraph lesson-points",
            class: { "content-audio": _vm.content.audio },
            domProps: { innerHTML: _vm._s(_vm.textContent) },
            on: { click: _vm.GoToLink }
          }),
          _vm.content.audio
            ? _c("AudioToggle", { attrs: { audio: _vm.content.audio } })
            : _vm._e()
        ],
        1
      ),
      _vm.showModal
        ? _c(
            "modal",
            {
              attrs: {
                "text-button": _vm.$t("done"),
                showCancelButton: _vm.showModalCancelButton,
                "click-handler": _vm.hideVocabularyBox
              },
              on: {
                close: function($event) {
                  _vm.showModal = false
                }
              }
            },
            [_c("vocabulary-box", { attrs: { vocabulary: _vm.selectedWord } })],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }