<template>
    <div class="lessonLayoutRow" :style="{order : content.order}">
        <div class="row">
            <div class="lessonLeftColumn col-md-5 col-sm-5 col-xs-5" :class="content.imagesOrientation">
                <div ref="leftcol">
                <image-details v-for="(image, index) in images" 
                :key="index" :imageDetails="image"
                :default-width="imageDetailsWidth" class="image-details-wrapper"/>
                </div>
            </div>
            <div class="lessonRightColumn col-md-7 col-sm-7 col-xs-7" :style="{'justify-content': content.alignment}">
                <essential-question v-show="false" v-if="essentialQuestionText" :key="content.essentialQuestion.id"
                :audio="essentialQuestionAudio"    :message="essentialQuestionText" />
                <div class="body" :class="content.imagesOrientation" :style="{ minHeight: '176px'}">
                    <div :class="{'audio': content.audio}">
                        <span  @click="GoToLink" v-html="textContent" ></span>
                    </div> 
                    <AudioToggle v-if="content.audio" :audio="content.audio"/>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="lessonLayoutRow" :style="{order : content.order}">
        <div class="paragraph" >
            <div class="container-fluid">
                <div class="row">
                    <div class="lessonLeftColumn" :class="content.imagesOrientation">
                        <image-details v-for="(image, index) in images" 
                        :key="index" :imageDetails="image"
                        :default-width="imageDetailsWidth"/>
                    </div>
                    <div class="lessonRightColumn" :style="{'justify-content': content.alignment}">
                        <essential-question v-show="false" v-if="essentialQuestionText" :key="content.essentialQuestion.id"
                        :audio="essentialQuestionAudio"    :message="essentialQuestionText" />
                        <div class="body" :class="content.imagesOrientation">
                            <div class="mb-0">
                                <span  @click="GoToLink" v-html="textContent" ></span>
                            </div> 
                            <AudioToggle v-if="content.audio" :audio="content.audio"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>  -->
    <!-- <div class="paragraph row" :style="{order : content.order}">
        <section class="column" :class="content.imagesOrientation">
            <image-details v-for="(image, index) in images" 
                            :key="index" :imageDetails="image"
                            :default-width="imageDetailsWidth"/>
        </section>
        <div class="column" :style="{'justify-content': content.alignment}">
             <essential-question  v-if="essentialQuestionText" :key="content.essentialQuestion.id"
                :audio="essentialQuestionAudio"    :message="essentialQuestionText" />
            <div class="body">
                <div>
                    <span  @click="GoToLink" v-html="textContent" ></span>
                </div> 
                <AudioToggle v-if="content.audio" :audio="content.audio"/>
            </div>
        </div>

        <modal v-if="showModal" :text-button="$t('done')"
            :click-handler="hideVocabularyBox" 
            :clickOutSideModalHandler="hideVocabularyBox"
            :showCancelButton="showModalCancelButton"
            :modalType="modalType"> <vocabulary-box :vocabulary="selectedWord" /></modal>
    </div>-->
</template>

<script>
import ParagraphMixin from './ParagraphMixin.js'

export default {
  name: 'LessonParagraph',
  mixins:[ParagraphMixin],
  data(){
      return{
          imageDetailsHeight: 0,
          finalHeight: null
      }
  },
  computed:{
      imageDetailsWidth(){
          return this.content.imagesOrientation === "right-side" ? 515 : 515; 
      }
  },
  methods:{
        getLeftColumnHeight(){
            var fullHeight = this.$refs.leftcol.clientHeight;
            var items = this.$refs.leftcol.childNodes;
            var lastItem = items[items.length-1];
            this.imageDetailsHeight = lastItem.lastElementChild.lastElementChild.clientHeight
            this.finalHeight = fullHeight - this.imageDetailsHeight;
        }
  },
  mounted(){
      
      
  },
  updated(){
      setTimeout(()=>{
           this.getLeftColumnHeight();
      },1000)
  }
}
</script>

<style lang="less">
.lessonLayoutRow {
    width: 100%;
    margin-bottom: 30px;
    .row {
        // justify-content: left;
        .lessonLeftColumn {
            width: 100%;
            margin: 0;
            display: inline-block;
            text-align: left;
            .image-details-container {
                text-align: center;
                .disclaimer {
                    text-align: left;
                }
                .image-details-image {
                    text-align: left;
                    max-width: 100%;
                    display: inline-block;
                    .title {
                        display:block;
                        text-align: center;
                        white-space: initial;
                    }
                    .img_detail {
                        position: relative;
                        &.m-bottom {
                            margin-bottom: 15px;
                        }
                        img{
                            max-width: 100% !important;
                        }
                        .audio-toggle {
                            right: 5px;
                            bottom: 5px;
                        }
                    }
                    .disclaimer {
                        display: block;
                    }
                    .image-details-caption {
                        margin: 0;
                        position: relative;
                        justify-content: left !important;
                        .image-caption-text {
                            width: 100%;
                            padding-left: 0;
                            margin-left: 0 !important;
                            &.captionAudio {
                                width: calc(100% - 34px);
                            }
                        }
                        i {
                            position: relative;
                            left: 0;
                            margin-left: 0;
                            bottom: 0;
                        }
                    }
                }
                
            } 
            &.right-side {
                // margin: 0 0 0 20px !important;
                .image-details-container {
                    text-align: left;
                }
                .drop-shadow {
                    filter: drop-shadow(10px 9px 0 rgba(0, 0, 0, 0.38));
                    -webkit-filter: drop-shadow(10px 10px 0 rgba(0, 0, 0, 0.5));
                }
            }
        }
        .lessonRightColumn {
            width: 100%;
            display: inline-block;
            position: relative;
            .body {
                background: #fff;
                padding: 15px 15px 15px 15px;
                // display: inline-block;
                display: flex;
                flex-wrap: wrap;
                // // height: 100%;
                // position: relative;
                // z-index: 1;
                max-width: 100%;
                -webkit-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.2);
                box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.2);
                div {
                    &.audio {
                        width: calc(100% - 29px);
                    }
                    width: 100%;
                    height: 100%;
                    overflow-y: auto;
                    // min-width: auto ;
                    // max-width: calc(100% - 58px);
                    // max-height: calc(100% - 30px);
                    // overflow-y: auto;
                    // margin-right: 0;
                    // margin-bottom: 0;
                    span {
                        p {
                            margin-top: 0;
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
                i {
                    display: inline-block;
                    margin-top: -2px;
                    margin-right: 0;
                    margin-left: 5px;
                }
                &.right-side {
                    right: 0;
                }
            }
        }
    }
}
.lesson{
    .paragraph{
        width: 100%;
        .column{
            // max-width: 700px;
            max-width: 100%;
        }
        .right-side{
            margin-right: auto;
            margin-left: auto;  
            .image-details-image {
                text-align: left;
            }
            // img{
            //     width: auto !important;
            // }
        }
        .left-side{
            margin-left: auto;
            .image-details-image {
                text-align: right;
            }
            // img{
            //     width: auto !important;
            // }
        }
    }
}
// .right-side{
//     padding-left: 32px;
// }
// .left-side{
//     padding-right: 32px;
// }
</style>