var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "container-fluid mb-30",
      style: { order: _vm.content.order }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "text-content" },
          [
            _vm.essentialQuestionText
              ? _c("essential-question", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: false,
                      expression: "false"
                    }
                  ],
                  key: _vm.content.essentialQuestion.id,
                  class: [_vm.content.orientation],
                  attrs: {
                    audio: _vm.content.essentialQuestion.audio,
                    message: _vm.essentialQuestionText
                  }
                })
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "body ",
                class: [_vm.content.orientation],
                style: { width: _vm.width + "px" }
              },
              [
                _c("div", { class: { contentAudio: _vm.content.audio } }, [
                  _c("span", {
                    domProps: { innerHTML: _vm._s(_vm.textContent) },
                    on: { click: _vm.GoToLink }
                  })
                ]),
                _vm.content.audio
                  ? _c("AudioToggle", { attrs: { audio: _vm.content.audio } })
                  : _vm._e()
              ],
              1
            ),
            _vm.showModal
              ? _c(
                  "modal",
                  {
                    attrs: {
                      "text-button": _vm.$t("done"),
                      showCancelButton: _vm.showModalCancelButton,
                      "click-handler": _vm.hideVocabularyBox
                    },
                    on: {
                      close: function($event) {
                        _vm.showModal = false
                      }
                    }
                  },
                  [
                    _c("vocabulary-box", {
                      attrs: { vocabulary: _vm.selectedWord }
                    })
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }