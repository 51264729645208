var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "single-image singleImageRow",
      class: _vm.content.isHorizontalMode ? "row" : "column",
      style: { order: _vm.content.order }
    },
    _vm._l(_vm.content.images, function(image, index) {
      return _c("image-details", { key: index, attrs: { imageDetails: image } })
    })
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }