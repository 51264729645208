var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "essential-question row ml-0 mr-0" },
    [
      _vm.brainIcon
        ? _c("img", {
            staticClass: "brain-image",
            attrs: { src: _vm.brainIcon },
            on: {
              click: function($event) {
                _vm.isBrainClicked = !_vm.isBrainClicked
              }
            }
          })
        : _vm._e(),
      _c("span", [_vm._v(_vm._s(_vm.info))]),
      _vm.audio && _vm.isBrainClicked
        ? _c("AudioToggle", { attrs: { audio: _vm.audio } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }