var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal" } }, [
    _c(
      "div",
      {
        staticClass: "modal-mask",
        on: {
          click: function() {
            if (_vm.clickOutSideModalHandler) {
              _vm.clickOutSideModalHandler()
            }
          }
        }
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-container flex-container gc-modal-mt",
            on: {
              click: function($event) {
                $event.stopPropagation()
              }
            }
          },
          [
            _vm.showCancelButton
              ? _c(
                  "button",
                  {
                    staticClass: "button button-close gc-btn-close",
                    on: {
                      click: function($event) {
                        _vm.$emit("close")
                      }
                    }
                  },
                  [_c("span", [_vm._v("×")])]
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "modal-body custom-scrollbar" },
              [_vm._t("default")],
              2
            ),
            _vm.showButtons
              ? _c("div", { staticClass: "modal-footer flex-container" }, [
                  _vm.showCancelButton
                    ? _c(
                        "button",
                        {
                          staticClass: "btn secondary",
                          on: {
                            click: function($event) {
                              _vm.$emit("close")
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("cancel")) +
                              "\n        "
                          )
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "button",
                    {
                      staticClass: "btn primary",
                      class: [{ "with-margin-left": _vm.showCancelButton }],
                      on: { click: _vm.clickHandler }
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(_vm.textButton) + "\n        "
                      )
                    ]
                  )
                ])
              : _vm._e()
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }