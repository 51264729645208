import AudioToggle from '@/common/AudioToggle.vue'
import VocabularyBox from './VocabularyBox.vue'
import Modal from '@/common/Modal.vue'
import EssentialQuestion from '@/common/EssentialQuestion.vue'
import ImageDetails from "@/common/ImageDetails.vue";

export default{
    components:{
        AudioToggle, 
        VocabularyBox, 
        Modal, 
        EssentialQuestion,
        ImageDetails
    }, 
    props:{
        content : Object
    },
    data(){
        return{
            selectedWord: '',
            showModal: false, 
            modalType: '',
            showModalCancelButton: false, 
            tagForClickableWord: '<strong class="clickable-link">',
            closingtagForClickableWord: '</strong>'
        }
    },
    computed:{
        images(){
            let images = this.content.images.map(i =>{
              i['url'] = this.getImageUrl(i.name)
              return i}) 
          return images
        }, 
        textContent(){
            let text = this.content.content 
            text = this.insertLinksInParagraph(this.biographies, text, true)
            return  this.insertLinksInParagraph(this.vocabulary, text)
        }, 
        vocabulary(){
            return this.$store.state.currentUnit
            ? this.$store.state.currentUnit.vocabularies
            : []
        },
        biographies(){
            return this.$store.getters.biographies || []
        },
        unitStories(){
            return this.$store.getters.unitStories || []
        },
        essentialQuestionText(){
            if(!this.content.essentialQuestion)
                return null
            let essentialQuestionText =  this.content.essentialQuestion.question
            return essentialQuestionText[this.locale].length > 0 
                ? essentialQuestionText
                : null
        },
        essentialQuestionAudio(){
            if(!this.content.essentialQuestion)
                return null
            return this.content.essentialQuestion.audio
        }

    },
    methods:{
        GoToLink(e){
            if(this.$store.state.isPreviewModeActive)
                return
            if(e.target.className != "clickable-link")
                return

            let wordClicked = e.target.innerText.toLowerCase().trim()

            let biographies = this.$store.getters.biographies || []
            let biography = biographies.filter(e => e.name)
                .find(e => e.name.trim().toLowerCase() === wordClicked)
            biography 
                ? this.GoToBiography(biography)
                : this.showVocabularyBox(wordClicked)
        },
        GoToBiography(biography){
            if(biography.route) 
                this.$router.push(biography.route)
        },
        showVocabularyBox(wordClicked){
            let vocabulary = this.$store.state.currentUnit
            ? this.$store.state.currentUnit.vocabularies
            : []

            let word = vocabulary.find(e => e.name.trim().toLowerCase() === wordClicked)
            if(!word)
                return;

            this.selectedWord = word
            this.modalType = "vocabularyModal";
            this.showModalCancelButton = false;
            this.showModal = true
        },
        hideVocabularyBox(){
            this.showModal = false
            this.showModalCancelButton = true; 
            this.modalType = ""; 
            this.selectedWord = ''
        },
        insertLinksInParagraph(items, paragraph, global = false){
            var name;
            items.forEach(e =>{
                if(e.name){
                    name = e.name.trim()
                    
                    paragraph = paragraph ? paragraph.replace(
                        new RegExp(`(?<=\\W)(${name})(?=\\W)`, `${global ? 'ig': 'i'}`), (match) =>{
                            return `${this.tagForClickableWord}${match}${this.closingtagForClickableWord}`
                        }) : ''
                        
                }
            })
            return paragraph 
        }
    }
}