var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.mapBackground
    ? _c(
        "div",
        {
          staticClass: "wrap-interactive-lesson",
          class: { "horizontal-mode": _vm.content.isHorizontalMode },
          style: { order: _vm.content.order }
        },
        [
          _c("div", { staticClass: "container-fluid" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass:
                    "interactive-lesson-image d-flex-interactive-lesson",
                  class: _vm.content.isHorizontalMode
                    ? "col-md-7 col-sm-7 col-xs-7 order-1"
                    : "col-md-12 col-sm-12 col-xs-12"
                },
                [
                  _c("img", {
                    ref: "leftcol",
                    staticClass: "bg",
                    attrs: { src: _vm.mapBackground },
                    on: { load: _vm.getImageWidth }
                  }),
                  _c("img", {
                    staticClass: "centered overlay overlay-image",
                    attrs: { src: _vm.overlay }
                  }),
                  _vm._l(_vm.routeImages, function(image) {
                    return _c("img", {
                      key: image,
                      staticClass: "centered",
                      attrs: { src: _vm.getImageUrl(image) }
                    })
                  })
                ],
                2
              ),
              _c(
                "div",
                {
                  staticClass: "buttons-box",
                  class: { "order-3": _vm.content.isHorizontalMode }
                },
                [
                  _c(
                    "div",
                    {
                      class:
                        this.points.length == 0
                          ? "interactive-firstchild-mt"
                          : ""
                    },
                    [
                      _vm.currentPointIndex > 0
                        ? _c("span", [
                            _vm.currentPointIndex >= 0
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn round primary",
                                    on: { click: _vm.previousPoint }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("back")) +
                                        "\n            "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        : _vm._e(),
                      _vm.currentPointIndex < this.points.length - 1
                        ? _c(
                            "button",
                            {
                              staticClass: "btn round primary float-right",
                              on: { click: _vm.nextPoint }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("next")) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "interactive-lesson-content",
                  class: _vm.content.isHorizontalMode
                    ? "col-md-5 col-sm-5 col-xs-5 order-2"
                    : "col-md-7 col-sm-9 col-xs-9",
                  style: _vm.content.isHorizontalMode
                    ? {
                        height: _vm.rightContentHeight + "px",
                        minHeight: "176px"
                      }
                    : ""
                },
                [
                  _vm.currentPoint
                    ? _c("point", {
                        key: _vm.currentPoint.id,
                        attrs: {
                          content: _vm.currentPoint,
                          "display-number": _vm.content.displayStepNumbers
                        }
                      })
                    : [
                        _c("div", { staticClass: "body" }, [
                          _c(
                            "span",
                            {
                              staticClass:
                                "legend interactive-lesson-direction-text"
                            },
                            [_vm._v(_vm._s(_vm.$t("clickNextLessonLegend")))]
                          )
                        ])
                      ]
                ],
                2
              )
            ])
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }