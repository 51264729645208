<template>
    <div class="single-image singleImageRow"
        :class="content.isHorizontalMode ? 'row' : 'column'"
        :style="{order : content.order}">
        <image-details v-for="(image, index) in content.images" :key="index"
        :imageDetails="image"/> 
    </div>
</template>

<script>
import ParagraphMixin from './ParagraphMixin.js'

export default {
  name: "OnlyImages",
  mixins:[ParagraphMixin],
}
</script>

<style lang="less" scoped>

.single-image{
    align-items: center;
    margin-bottom: 30px;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: flex-start;
    column-gap: 8px;
    .image-details-container {
        margin-right: 0;    
        .image-details-image{
            &.shadowed-image{
                padding-left: 12px;
            }
        }
    }
}

</style>
