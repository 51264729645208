<template>
    <div class="lesson-content">
        <div class="body row">
            <span  :style="{opacity: displayNumber ? 1: 0}">{{content.number}}</span>
            <p class="map-paragraph lesson-points" :class="{'content-audio': content.audio}"  @click="GoToLink" v-html="textContent" ></p>
            <AudioToggle v-if="content.audio" :audio="content.audio"/>
        </div>
        <modal v-if="showModal" :text-button="$t('done')" 
        :showCancelButton="showModalCancelButton"
        :click-handler="hideVocabularyBox" @close="showModal = false">
            <vocabulary-box :vocabulary="selectedWord" />
        </modal>
    </div>
    <!-- <div class="lesson-point col-md-9 pl-0 pr-0">
        <div class="body">
            <span  :style="{opacity: displayNumber ? 1: 0}">{{content.number}}</span>
            <p class="map-paragraph lesson-points"  @click="GoToLink" v-html="textContent" ></p>
            <AudioToggle v-if="content.audio" :audio="content.audio"/>
        </div>
        <modal v-if="showModal" :text-button="$t('done')" 
        :showCancelButton="showModalCancelButton"
        :click-handler="hideVocabularyBox" @close="showModal = false">
            <vocabulary-box :vocabulary="selectedWord" />
        </modal>
    </div> -->
</template>
<script>
import ParagraphMixin from './ParagraphMixin.js'
export default {
  name: 'LessonPoint',
  mixins:[ParagraphMixin],
  props:{
      displayNumber:{
          type: Boolean, 
          required: false, 
          default: true
      }
  }
}
</script>
<style lang="less">

  &.horizontal-mode {
      .lesson-point {

     .body{
        // height: 431px;
        height: 100%;
        margin-bottom: 0;
     }
      }
    }
.lesson-point{
    min-height: 136px;
    // width: 960px;
    // max-width: 960px;
    transition: all .5s ease;
    margin: auto;
    margin-top: -40px;
    
    .body{

    height: 136px;
        span{
            transition: all .5s ease;
            height: 24px;
            min-width: 30px;
            color: #3A4DFD;
            font-size: 19.64px;
            font-weight: bold;
            margin-left: 24px;
            margin-top: 24px;
            font-family: 'Montserrat', sans-serif;
        }
    
        p{
            transition: all .5s ease;
            width: 100%;
            // min-width: 840px;
            color: #0A1554;
            font-family: 'Roboto', sans-serif;
            font-size: 18px;
            font-weight: 300;
            letter-spacing: 0.25px;
            line-height: 24px;
            white-space: pre-line;
            padding-right: 15px;
        }
        .lesson-points {
            margin-block: initial;
            // margin-top: 5px;
            margin-right: 4px;
            margin-bottom: 18px;
        }
        .audio-toggle{
            margin-top: 8px !important;
            margin-left: 5px;
            margin-right: 10px !important;
        }
    }
}

.lesson-content {
    height: 100%;
    background: #fff;
    .body{
        background: #fff;
        // padding: 15px;
        text-align: left !important;
        margin-left: 0;
        margin-right: 0;
        height: 100%;
        span{
            transition: all .5s ease;
            height: 24px;
            min-width: 3%;
            color: #3A4DFD;
            font-size: 19.64px;
            font-weight: bold;
            font-family: 'Montserrat', sans-serif;
        }
        p{
            width: 97%;
            // height: 100%;
            overflow-y: initial;
            overflow-x: initial;
            transition: all .5s ease;
            color: #0A1554;
            font-family: 'Roboto', sans-serif;
            font-size: 18px;
            font-weight: 300;
            letter-spacing: 0.25px;
            line-height: 24px;
            white-space: pre-line;
            margin-top: 0;
            p {
                width: 100%;
                margin-bottom: 0;
                overflow-x: initial;
                overflow-y: initial;
            }
            &.content-audio {
                width: 93%;
            }
        }
        .audio-toggle{
            margin-left: 5px;
        }
    }
}
.horizontal-mode {
    .lesson-content {
        .body{
            span{ 
                min-width: 7%;
            }
            p{ 
                height: auto;
                overflow-y: initial;
                width: 93%;
                p {
                    width: 100%;
                }
                &.content-audio {
                width: 87%;
            }
            }
        }
    }
}
</style>
