<template>
  <div
    class="section-title"
    :class="[content.orientation]"
    :style="componentStyles"
  >
    <img
      v-if="image"
      :src="background"
      :style="{ backgroundImage: background, maxWidth: width + '%' }"
    />
    <span
      class="section-title-text"
      :style="{
        backgroundColor: !this.hasImage ? this.content.background : '',
        color: content.color,
      }"
      >{{ content.title }}</span
    >
  </div>
</template>

<script>
export default {
  name: "SectionTitle",
  props: {
    content: Object,
    hasImage: "",
  },
  data() {
    return {};
  },
  computed: {
    background() {
      return this.getImageUrl(this.image.name);
    },
    width() {
      return (100 / 12) * this.content.size;
    },
    componentStyles() {
      return {
        backgroundColor: !this.hasImage ? '' : "",
        "min-height": this.hasImage ? "145px" : "41px",
        opacity: !this.hasImage ? this.content.opacity / 100 : 1,
        width: this.width + "%",
        order: this.content.order,
      };
    },
    image() {
      return this.content.images[0];
    },
  },
};
</script>

<style lang="less">
.wrap-lesson {
  .section-title {
    // margin-top: 20px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    position: relative;
    background-color: unset;
    z-index: 0;
    text-align: center;
    width: 100%;
    text-align: center;
    span {
      // position: absolute;
      margin: auto;
      // height: 41px;
      color: #3a4dfd;
      // font-family: 'Montserrat', sans-serif;
      font-size: 29px;
      font-weight: 600;
      letter-spacing: 0.25px;
      line-height: 34px;
      text-align: center;
      justify-self: center;
      width: 100%;
      padding: 8px 25px;
      white-space: pre-wrap !important;
      overflow: unset;
      margin-top: 0 !important;
    }
    img {
      width: auto;
      height: auto;
      z-index: -1;
      margin: auto;
    }
  }
}
.section-title-text {
  margin-top: 8px !important;
  padding-bottom: 8px !important;
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>