var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "global_connection global-connection-modal-box" },
    [
      _c("div", { staticClass: "global_connection_top" }, [
        _c("div", { staticClass: "global_connection_left" }, [
          _c("img", { attrs: { src: _vm.gcImage } })
        ]),
        _c("div", { staticClass: "global_connection_right" }, [
          _c("img", { attrs: { src: _vm.gcMap } })
        ])
      ]),
      _c("div", { staticClass: "global_connection_bottom" }, [
        _c(
          "div",
          { staticClass: "global_connection_left" },
          [
            _c("img", { attrs: { src: _vm.imageTitle } }),
            _c("disclaimer", {
              staticClass: "global_connection_left_desc",
              attrs: { disclaimer: _vm.getGcText(5) }
            }),
            _c("div", { staticClass: "textBox" }, [
              _c(
                "div",
                { staticClass: "global_connection_left_audio" },
                [
                  _c("audio-toggle", {
                    attrs: {
                      audio: _vm.getGcAudio(3),
                      "white-background": true
                    }
                  })
                ],
                1
              ),
              _c("p", {
                staticClass: "custom-scrollbar desc-text",
                domProps: { innerHTML: _vm._s(_vm.getGcText(3)) }
              })
            ])
          ],
          1
        ),
        _c("div", { staticClass: "global_connection_right" }, [
          _c(
            "div",
            { staticClass: "global_audio" },
            [_c("audio-toggle", { attrs: { audio: _vm.getGcAudio(4) } })],
            1
          ),
          _c("p", {
            staticClass: "custom-scrollbar main-text overflow-clip l-height",
            domProps: { innerHTML: _vm._s(_vm.getGcText(4)) }
          })
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }