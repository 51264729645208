<template>
  <div
    class="wrap-interactive-lesson"
    :class="{ 'horizontal-mode': content.isHorizontalMode }"
    v-if="mapBackground"
    :style="{ order: content.order }"
  >
    <div class="container-fluid">
      <div class="row">
        <div
          class="interactive-lesson-image d-flex-interactive-lesson"
          :class="
            content.isHorizontalMode
              ? 'col-md-7 col-sm-7 col-xs-7 order-1'
              : 'col-md-12 col-sm-12 col-xs-12'
          "
        >
          <img
            ref="leftcol"
            :src="mapBackground"
            class="bg"
            v-on:load="getImageWidth"
          />
          <img :src="overlay" class="centered overlay overlay-image" />
          <img
            v-for="image in routeImages"
            class="centered"
            :key="image"
            :src="getImageUrl(image)"
          />
        </div>
        <div
          class="buttons-box"
          :class="{ 'order-3': content.isHorizontalMode }"
        >
          <div
            :class="this.points.length == 0 ? 'interactive-firstchild-mt' : ''"
          >
            <span v-if="currentPointIndex > 0">
              <button
                class="btn round primary"
                v-if="currentPointIndex >= 0"
                @click="previousPoint"
              >
                {{ $t("back") }}
              </button>
            </span>
            <button
              class="btn round primary float-right"
              v-if="currentPointIndex < this.points.length - 1"
              @click="nextPoint"
            >
              {{ $t("next") }}
            </button>
          </div>
        </div>
        <div
          class="interactive-lesson-content"
          :class="
            content.isHorizontalMode
              ? 'col-md-5 col-sm-5 col-xs-5 order-2'
              : 'col-md-7 col-sm-9 col-xs-9'
          "
          :style="
            content.isHorizontalMode
              ? { height: rightContentHeight + 'px',minHeight: '176px' }
              : ''
          "
        >
          <point
            v-if="currentPoint"
            :content="currentPoint"
            :display-number="content.displayStepNumbers"
            :key="currentPoint.id"
          />
          <template v-else>
            <div class="body">
              <span class="legend interactive-lesson-direction-text">{{
                $t("clickNextLessonLegend")
              }}</span>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="interactive-lesson" :class="{'horizontal-mode' : content.isHorizontalMode}"
     v-if="mapBackground" :style="{order : content.order}" >
       <div class="container-fluid">
         <div class="row">
          <div class="image-container">
            <img :src="mapBackground" class="bg" v-on:load="getImageWidth">
            <img :src="overlay" class="centered overlay">
            <img v-for="image in routeImages" class="centered"
                    :key="image" :src="getImageUrl(image)">
          </div>
          <div class=" buttons w-100">
          <span v-if="currentPointIndex > 0">
              <button class="btn round secondary" v-if="currentPointIndex >= 0"
            @click="previousPoint">{{$t('back')}}</button>
          </span>
            <button class="btn round primary float-right" v-if="currentPointIndex <  this.points.length-1"
              @click="nextPoint">{{$t('next')}}</button>
          </div>
        <br/>
          <point v-if="currentPoint" :content="currentPoint"
            :display-number="content.displayStepNumbers" :key="currentPoint.id" />
          <template v-else>
            <div class="map-point body col-md-9 pl-0 pr-0">
              <span class="legend">{{$t('clickNextLessonLegend')}}</span>
            </div>
          </template>
         </div>
       </div>
    </div> -->
</template>

<script>
import ParagraphMixin from "./ParagraphMixin.js";
import LessonPoint from "./LessonPoint.vue";
export default {
  name: "InteractiveLesson",
  mixins: [ParagraphMixin],
  components: {
    point: LessonPoint,
  },
  data() {
    return {
      currentPointIndex: 0,
      routeImages: "",
      rightContentHeight: null
    };
  },
  computed: {
    points() {
      return this.content.routePoints;
    },
    currentPoint() {
      return this.points[this.currentPointIndex];
    },
    mapBackground() {
      let backgroundImageName;
      this.content.images[0]
        ? (backgroundImageName = this.content.images[0].name)
        : (backgroundImageName = null);
      return this.getImageUrl(backgroundImageName);
    },
    overlay() {
      if (!this.content.images[1]) return;
      let backgroundImage = this.content.images[1].name;
      return this.getImageUrl(backgroundImage);
    },
  },
  methods: {
    nextPoint() {
      if (this.currentPointIndex >= this.points.length - 1) return;
      this.currentPointIndex++;
    },
    previousPoint() {
      if (this.currentPointIndex == -1) return;
      this.currentPointIndex--;
    },
    getLeftColumnHeight() {
      var contentHeight = this.$refs.leftcol.clientHeight;
      this.rightContentHeight = contentHeight;
    },
  },
  mounted() {
    // setInterval(() => {
    //   this.getLeftColumnHeight();
    // }, 1000);
  },
  updated(){
    setTimeout(()=>{
           this.getLeftColumnHeight();
      },1000)
  },
  watch: {
    currentPoint() {
      this.routeImages =
        this.currentPointIndex === 0 ? this.currentPoint.images : [];
    },
    currentPointIndex(value) {
      let images = [];
      if (value < 0) {
        this.routeImages = [];
        return;
      }
      !this.content.isProgressiveMap
        ? (images = this.points[value].images)
        : this.points.forEach((e, index) => {
            if (index <= value) e.images.forEach((i) => images.push(i));
          });

      this.routeImages = images;
    },
  },
};
</script>

<style lang="less" scoped>
.lesson .interactive-lesson {
  width: 1280px;
  margin: 20px auto 0;
  flex-direction: column;
  position: relative;
  button {
    &:focus {
      outline: none;
    }
  }
  // .lesson-point {
  //   // margin-top: -44px !important;
  // }
  .image-container {
    display: flex;
    position: relative;
    // max-height: 514px;
    // max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    // img:first-child {
    //   margin: auto;
    // }
    img {
      // max-height: 514px;
      max-width: 100%;
    }
  }
  .buttons {
    position: relative;
    padding: 8px 0 0 0;
    .float-right {
      float: right;
    }
    .primary {
      margin-left: auto;
    }
  }
  .overlay {
    z-index: 200;
  }

  .legend {
    text-align: center;
    font-size: 24px;
    height: auto;
    margin: auto;
    color: black;
  }

  &.horizontal-mode {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .image-container {
      margin-bottom: 0;
      display: flex;
      margin-inline: 0px !important;
    }
    img {
      max-width: 700px;
    }
    .lesson-point {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
      // order: 1;
      width: 580px;
      margin: unset;
      margin-right: auto;
      min-height: auto;
      height: calc(100% - 48px) !important;
      .body {
        padding-bottom: 32px;
        width: 840px !important;
        height: 288px !important;
      }
      p {
        max-width: 440px;
        min-width: 440px;
      }
    }
    .buttons {
      order: 2;
      min-width: 100%;
    }
    .legend {
      max-width: 380px;
      white-space: normal;
    }
  }
}

.wrap-lesson .wrap-interactive-lesson {
  width: 100%;
  position: relative;
  margin-bottom: 30px;
  .interactive-lesson-image {
    position: relative;
    padding-left: 0;
    padding-right: 0;
    img {
      max-width: 100%;
    }
  }
  .buttons-box {
    width: 100%;
    margin-top: 15px;
    .float-right {
      float: right;
    }
  }
  .interactive-lesson-content {
    background: #fff;
    padding-left: 0;
    padding-right: 0;
    margin: 0 auto;
    height: 120px;
    position: relative;
    margin-top: -40px;
    z-index: 1;
    overflow-y: auto;
    -webkit-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.2);
    padding: 15px;
    .lesson-content {
      height: 100%;
      overflow-y: auto;
    }
    .body {
      text-align: center;
      height: 100%;
      padding: 15px;
      .legend {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        display: inline-block;
      }
    }
  }
  &.horizontal-mode {
    .interactive-lesson-content {
      // height: inherit;
      overflow-y: auto;
      margin-top: 0;
    }
  }
}
.d-flex-interactive-lesson {
  display: flex;
  justify-content: center;
}
.interactive-lesson-direction-text {
  font-size: 24px;
  font-family: "Roboto", sans-serif;
  color: #000;
}
.interactive-firstchild-mt {
  margin-top: 48px;
}
.overlay-image{
  z-index: 1;
}
</style>
