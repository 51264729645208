<template>
  <div
    class="global-connection"
    :class="{ 'horizontal-mode': content.isHorizontalMode }"
    v-if="gcImage"
    :style="{ order: content.order }"
  >
    <div class="global-points" v-bind:style="heroImage">
      <img :src="gcImage" class="gcImage" @click="showModal = true" />
      <div class="gcContent col-md-6 col-sm-6 col-xs-6 offset-md-3 offset-sm-3 offset-xs-3"><div class="legends">{{ content.title }}</div></div>
    </div>
    <global-connections-modal
      v-if="showModal"
      :click-handler="handleGlobalConnections"
      :text-button="$t('ok')"
      @close="showModal = false"
      :showButtons="false"
    >
      <global-connections-data
        :routePoints="routePoints"
      ></global-connections-data>
    </global-connections-modal>
  </div>
    <!-- <div
    class="global-connection"
    :class="{ 'horizontal-mode': content.isHorizontalMode }"
    v-if="gcImage"
    :style="{ order: content.order }"
  >
    <div class="map-points gc_height body overflow-y-clip logo-height" v-bind:style="heroImage">
      <img :src="gcImage" class="gc" @click="showModal = true" style="position:absolute;" />
      <div style="display: flex"><span class="legends">{{ content.title }}</span></div>
    </div>
    <global-connections-modal
      v-if="showModal"
      :click-handler="handleGlobalConnections"
      :text-button="$t('ok')"
      @close="showModal = false"
      :showButtons="false"
    >
      <global-connections-data
        :routePoints="routePoints"
      ></global-connections-data>
    </global-connections-modal>
  </div> -->
</template>

<script>
import ParagraphMixin from "./ParagraphMixin.js";
import GlobalConnectionsPoint from "./GlobalConnectionsPoint.vue";
import GlobalConnectionsModal from "./GlobalConnectionsModal.vue";
import GlobalConnectionsData from "./GlobalConnectionsData.vue";
import ImageUpload from "@/components/BackOffice/ComponentSetters/ImageUpload.vue";
export default {
  name: "GlobalConnections",
  mixins: [ParagraphMixin],
  components: {
    point: GlobalConnectionsPoint,
    GlobalConnectionsModal,
    GlobalConnectionsData,
    ImageUpload,
  },
  mounted() {
    let routes = this.clone(this.content.routePoints);
    let num = 1;

    routes.map((r) => {
      r.number = num;
      num++;
    });
    this.routePoints = routes;
  },
  data() {
    return {
      showModal: false,
      routePoints: [],
    };
  },
  computed: {
    points() {
      return this.content.routePoints;
    },
    gcImage() {
      return this.getGcImageLink();
    },
    heroImage() {
      return {
        backgroundImage: `url(${require("@/assets/GlobalBanner.png")})`,
      };
    },
  },
  methods: {
    clone(source) {
      return JSON.parse(JSON.stringify(source));
    },
    handleGlobalConnections() {
      this.showModal = false;
    },
    // getImageLink(index) { //Using this we can access the current paragraph images
    //   let backgroundImageName;
    //   this.content.images[index]
    //     ? (backgroundImageName = this.content.images[index].name)
    //     : (backgroundImageName = null);
    //   return this.getImageUrl(backgroundImageName);
    // },
    getGcImageLink() {
      let backgroundImageName = null;
      let routePoint = this.points[0];
      if (routePoint && routePoint.images[0])
        backgroundImageName = this.points[0].images[0];
      return this.getImageUrl(backgroundImageName);
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.logo-height{
  height: 156px !important;
}
.gc_height {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 140px;
  height: 160px;
  background-size: cover;
}

.lesson .interactive-map {
  // width: 100%;
  margin: 20px auto 0;
  flex-direction: column;

  button {
    &:focus {
      outline: none;
    }
  }
  .image-container {
    position: relative;
    max-height: 514px;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    img:first-child {
      margin: auto;
    }
    img {
      max-height: 514px;
      max-width: 1280px;
    }
  }
  .buttons {
    position: relative;
    padding: 8px;
    .primary {
      margin-left: auto;
    }
  }
  .overlay {
    z-index: 200;
  }

  .legends {
    text-align: center;
    font-size: 20px;
    height: auto;
    margin: auto;
    color: white;
    font-weight: 600;
    margin-bottom: 80px;
    text-transform: uppercase;
    margin-top: 10px;
    display: flex;
    align-items: center;
    max-width: 700px;
    white-space: unset;
  }

  .horizontal-mode > .map-points {
    order: 1;
    max-width: 550px;
    margin: unset;
    margin-right: auto;
    min-height: 100%;
    // background-image: url('@/assets/icons/info_outline.svg');
    p {
      max-width: 440px;
      min-width: 440px;
    }
  }
  &.horizontal-mode {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .image-container {
      max-width: 700px;
      margin: unset;
      margin-left: auto;
      order: 0;
    }
    img {
      max-width: 700px;
    }
    .map-point {
      order: 1;
      max-width: 550px;
      margin: unset;
      margin-right: auto;
      min-height: 100%;
      // background-image: url('@/assets/icons/info_outline.svg');
      p {
        max-width: 440px;
        min-width: 440px;
      }
    }
    .buttons {
      order: 2;
      min-width: 1280px;
    }
    .legend {
      max-width: 380px;
      white-space: normal;
    }
  }
  .gc {
    position: absolute;
    // top: 21%;
    left: 1%;
    width: 250px;
    // height: 120px;
    // margin-top: -19px;
    object-fit: contain;
  }
}
.global-connection {
  width: 100%;
  margin-bottom: 30px;
  -webkit-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.2);
                box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.2);
  .global-points {
    width: 100%;
    height: 152px;
    position: relative;
    .gcImage {
      max-height: 140px;
      width: 250px;
      position: absolute;
      top: 6px;
      left: 1%;
      z-index: 1;
    }
    // .gcContent {
    //   text-align: center;
    //   font-size: 20px;
    //   margin: auto;
    //   color: white;
    //   font-weight: 600;
    //   margin-bottom: 80px;
    //   text-transform: uppercase;
    //   padding-top: 25px;
    //   max-width: 700px;
    //   white-space: unset;
    //   letter-spacing: 0.25px;
    //   line-height: 24px;
    // }
    .gcContent {
      font-size: 20px;
      color: white;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.25px;
      line-height: 24px;
      text-align: center;
      min-height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
