var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.gcImage
    ? _c(
        "div",
        {
          staticClass: "global-connection",
          class: { "horizontal-mode": _vm.content.isHorizontalMode },
          style: { order: _vm.content.order }
        },
        [
          _c("div", { staticClass: "global-points", style: _vm.heroImage }, [
            _c("img", {
              staticClass: "gcImage",
              attrs: { src: _vm.gcImage },
              on: {
                click: function($event) {
                  _vm.showModal = true
                }
              }
            }),
            _c(
              "div",
              {
                staticClass:
                  "gcContent col-md-6 col-sm-6 col-xs-6 offset-md-3 offset-sm-3 offset-xs-3"
              },
              [
                _c("div", { staticClass: "legends" }, [
                  _vm._v(_vm._s(_vm.content.title))
                ])
              ]
            )
          ]),
          _vm.showModal
            ? _c(
                "global-connections-modal",
                {
                  attrs: {
                    "click-handler": _vm.handleGlobalConnections,
                    "text-button": _vm.$t("ok"),
                    showButtons: false
                  },
                  on: {
                    close: function($event) {
                      _vm.showModal = false
                    }
                  }
                },
                [
                  _c("global-connections-data", {
                    attrs: { routePoints: _vm.routePoints }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }