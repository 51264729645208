<template>
    <div class="container-fluid mb-30" :style="{order : content.order}">
        <div class="row">
            <div class="text-content" >
                <essential-question v-show="false"  :class="[content.orientation]" v-if="essentialQuestionText" :key="content.essentialQuestion.id"
                        :audio="content.essentialQuestion.audio"    :message="essentialQuestionText" />
                <div class="body " :style="{width: width + 'px'}" :class="[content.orientation]">
                    <div :class="{'contentAudio': content.audio}">
                        <span  @click="GoToLink" v-html="textContent" ></span>
                    </div> 
                    <AudioToggle v-if="content.audio" :audio="content.audio"/>
                </div>
                <modal v-if="showModal" :text-button="$t('done')" 
                :showCancelButton="showModalCancelButton"
                :click-handler="hideVocabularyBox" @close="showModal = false">
                    <vocabulary-box :vocabulary="selectedWord" />
                </modal>
            </div>
        </div>
    </div>
</template>

<script>
import ParagraphMixin from './ParagraphMixin.js'
export default {
  name: 'LessonTextContent',
  mixins:[ParagraphMixin],
  computed:{
    width(){
        return  ((1280/12)* this.content.size)
    }, 
  }
}
</script>

<style lang="less">
    .mb-30{
        margin-bottom: 30px;
    }
    .text-content{
        width: 100% !important;
        margin-top: 0 !important;
        .essential-question  {
            width: 100% !important;
        }
        .body{
            width: 100%;
            background: #fff;
            padding: 15px;
            // max-width: 1280px !important;
            // margin-top: 32px;
            max-width: 100% !important;
            display: flex;
            flex-wrap: wrap;
            -webkit-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.2);
                box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.2);
            div{
                // max-width: 1280px !important;
                width: 100%;
                &.contentAudio {
                    width: calc(100% - 24px);
                }
                span {
                    p {
                        margin-top: 0;
                        &:last-child{
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }

</style>