var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "video-content", style: { order: _vm.content.order } },
    [
      _c("video-player", {
        attrs: {
          id: _vm.content.id,
          url: _vm.videoUrl,
          thumbnail: _vm.thumbnail,
          width: 700,
          height: 394
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }