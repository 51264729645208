var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "section-title",
      class: [_vm.content.orientation],
      style: _vm.componentStyles
    },
    [
      _vm.image
        ? _c("img", {
            style: {
              backgroundImage: _vm.background,
              maxWidth: _vm.width + "%"
            },
            attrs: { src: _vm.background }
          })
        : _vm._e(),
      _c(
        "span",
        {
          staticClass: "section-title-text",
          style: {
            backgroundColor: !this.hasImage ? this.content.background : "",
            color: _vm.content.color
          }
        },
        [_vm._v(_vm._s(_vm.content.title))]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }