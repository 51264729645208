<template>
  <div
    class="wrap-interactive-map"
    :class="{ 'horizontal-mode': content.isHorizontalMode }"
    v-if="mapBackground"
    :style="{ order: content.order }"
  >
    <div class="container-fluid">
      <div class="row">
        <div
          class="interactive-map-image d-flex-interactivemap"
          :class="
            content.isHorizontalMode
              ? 'col-md-7 col-sm-7 col-xs-7 order-1'
              : 'col-md-12 col-sm-12 col-xs-12'
          "
        >
          <img
            ref="leftcol"
            :src="mapBackground"
            class="bg"
            v-on:load="getImageWidth"
          />
          <img :src="overlay" class="centered overlay overlay-image" />
          <img
            v-for="image in routeImages"
            class="centered"
            :key="image"
            :src="getImageUrl(image)"
          />
        </div>
        <div
          class="buttons-box"
          :class="{ 'order-3': content.isHorizontalMode }"
        >
          <div
            :class="this.points.length == 0 ? 'interactive-firstchild-mt' : ''"
          >
            <button
              class="btn round primary"
              v-if="currentPointIndex >= 0"
              @click="previousPoint"
            >
              {{ $t("back") }}
            </button>
            <button
              class="btn round primary float-right"
              v-if="currentPointIndex < this.points.length - 1"
              @click="nextPoint"
            >
              {{ $t("next") }}
            </button>
          </div>
        </div>
        <div
          class="interactive-map-content"
          :class="
            content.isHorizontalMode
              ? 'col-md-5 col-sm-5 col-xs-5 order-2'
              : 'col-md-7 col-sm-9 col-xs-9'
          "
          :style="
            content.isHorizontalMode
              ? { 'max-height': rightContentHeight + 'px', minHeight: '176px' }
              : ''
          "
        >
          <point
            class="map-point-h-0"
            v-if="currentPoint"
            :content="currentPoint"
            :display-number="content.displayStepNumbers"
            :key="currentPoint.id"
          />
          <template v-else>
            <div class="body">
              <span class="legend" style="padding-right:12px">{{ $t("clickNextMapLegend") }}</span>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="interactive-map" :class="{'horizontal-mode' : content.isHorizontalMode}"
     v-if="mapBackground" :style="{order : content.order}" >
      <div class="container-fluid">
        <div class="row">
          <div class="image-container ">
            <img :src="mapBackground" class="bg" v-on:load="getImageWidth">
            <img :src="overlay" class="centered overlay">
            <img v-for="image in routeImages" class="centered"
                    :key="image" :src="getImageUrl(image)">
          </div>
        <div class="buttons">
          <button class="btn round secondary" v-if="currentPointIndex >= 0"
          @click="previousPoint">{{$t('back')}}</button>
          <button class="btn round primary float-right" v-if="currentPointIndex <  this.points.length-1"
            @click="nextPoint">{{$t('next')}}</button>
        </div>
      <point v-if="currentPoint" :content="currentPoint"
        :display-number="content.displayStepNumbers" :key="currentPoint.id" />
      <template v-else>
        <div class="map-point body col-md-9 pl-0 pr-0 mt-40">
          <span class="legend">{{$t('clickNextMapLegend')}}</span>
        </div>
      </template>

        </div>
      </div>
    </div> -->
</template>

<script>
import ParagraphMixin from "./ParagraphMixin.js";
import MapPoint from "./MapPoint.vue";
export default {
  name: "InteractiveMap",
  mixins: [ParagraphMixin],
  components: {
    point: MapPoint,
  },
  data() {
    return {
      currentPointIndex: -1,
      routeImages: "",
      rightContentHeight: null
    };
  },
  computed: {
    points() {
      return this.content.routePoints;
    },
    currentPoint() {
      return this.points[this.currentPointIndex];
    },
    mapBackground() {
      let backgroundImageName;
      this.content.images[0]
        ? (backgroundImageName = this.content.images[0].name)
        : (backgroundImageName = null);
      return this.getImageUrl(backgroundImageName);
    },
    overlay() {
      if (!this.content.images[1]) return;
      let backgroundImage = this.content.images[1].name;
      return this.getImageUrl(backgroundImage);
    },
  },
  methods: {
    nextPoint() {
      if (this.currentPointIndex >= this.points.length - 1) return;
      this.currentPointIndex++;
    },
    previousPoint() {
      if (this.currentPointIndex == -1) return;
      this.currentPointIndex--;
    },
    getLeftColumnHeight() {
      var contentHeight = this.$refs.leftcol.clientHeight;
      this.rightContentHeight = contentHeight
    },
  },
  mounted() {
    // setInterval(() => {
    //   this.getLeftColumnHeight();
    // }, 1000);
  },
  updated(){
    setTimeout(()=>{
           this.getLeftColumnHeight();
      },1000)
  },
  watch: {
    currentPointIndex(value) {
      let images = [];
      if (value < 0) {
        this.routeImages = [];
        return;
      }
      !this.content.isProgressiveMap
        ? (images = this.points[value].images)
        : this.points.forEach((e, index) => {
            if (index <= value) e.images.forEach((i) => images.push(i));
          });

      this.routeImages = images;
    },
  },
};
</script>

<style lang="less">
.wrap-lesson .interactive-map {
  width: 1280px;
  margin: 20px auto 0px;
  flex-direction: column;
  position: relative;
  .map-point {
    height: auto !important;
    padding-bottom: 0;
    &.body {
      padding-bottom: 0;
      .legend {
        height: auto;
        margin: auto;
      }
    }
    &.mt-40 {
      margin-top: -40px;
    }
  }
  .image-container {
    // margin-bottom: 5px;
    img:first-child {
      margin: auto;
      width: inherit;
      // width: 740px;
      // height: 431px;
    }
  }
  &.horizontal-mode {
    .image-container {
      margin-bottom: 0;
      display: flex;
      margin-inline: 0px !important;
    }
    .body {
      height: 100% !important;
      overflow: auto;
      margin-bottom: 0;
    }
    .map-point {
      position: absolute;
      right: 0;
      height: 431px;
      padding-bottom: 0;
    }
  }
  .body {
    // height: 100%;
    height: 136px;
    overflow: auto;
    padding-bottom: 0;
    box-sizing: border-box;
    margin-bottom: 0;
    .legend {
      margin-bottom: 0;
    }
    span {
      // color: #fff;
      margin-top: 5px;
      font-weight: bold;
      font-family: "Roboto", sans-serif !important;
    }
    p {
      color: #000;
      margin-right: 10px;
      margin-top: 5px;
      max-width: 840px !important;
      min-width: auto !important;
    }
  }
  .buttons {
    min-width: auto !important;
    width: 100% !important;
    box-sizing: border-box;
    .float-right {
      float: right;
    }
  }
  button {
    &:focus {
      outline: none;
    }
  }
  .image-container {
    position: relative;
    margin-inline: 26px;
    // max-height: 514px;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    img:first-child {
      margin: auto;
    }
    img {
      // max-height: 514px;
      max-width: 100%;
    }
  }
  .buttons {
    position: relative;
    padding: 8px 0 0 0;
    .primary {
      margin-left: auto;
    }
  }
  .overlay {
    // height: 240px;
    width: auto;
    z-index: 200;
  }

  .legend {
    text-align: center;
    font-size: 24px;
    height: auto;
    margin: auto;
    color: black !important;
  }

  &.horizontal-mode {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .image-container {
      max-width: 700px;
      margin: unset;
      margin-left: auto;
      order: 0;
    }
    img {
      max-width: 700px;
    }
    .map-point {
      order: 1;
      max-width: 580px;
      margin: unset;
      margin-right: auto;
      height: calc(100% - 48px) !important;
      min-height: auto !important;
      margin-top: 0 !important;
      p {
        max-width: 440px;
        min-width: 440px;
      }
    }
    .buttons {
      order: 2;
      min-width: 1280px;
    }
    .legend {
      max-width: 380px;
      white-space: normal;
    }
  }
}
.wrap-interactive-map {
  width: 100%;
  position: relative;
  margin-bottom: 30px;
  .interactive-map-image {
    position: relative;
    padding-left: 0;
    padding-right: 0;
    img {
      max-width: 100%;
    }
  }
  .buttons-box {
    width: 100%;
    margin-top: 15px;
    .float-right {
      float: right;
    }
  }
  .interactive-map-content {
    background: #fff;
    padding-left: 0;
    padding-right: 0;
    margin: 0 auto;
    height: 120px;
    position: relative;
    margin-top: -40px;
    z-index: 1;
      overflow-y: auto;
    -webkit-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.2);
    padding: 15px;
    .map-content {
      height: 100%;
      overflow-y: auto;
    }
    .body {
      text-align: center;
      height: 100%;
      .legend {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        display: inline-block;
        font-size: 24px;
        color: #000;
      }
    }
  }
  &.horizontal-mode {
    .interactive-map-content {
      height: inherit;
      overflow-y: auto;
      margin-top: 0;
    }
  }
}
.d-flex-interactivemap {
  display: flex;
  justify-content: center !important;
}
.interactive-firstchild-mt {
  margin-top: 48px;
}
.map-point-h-0{
  height: unset;
  padding-right: 12px !important;
}
.overlay-image{
  z-index: 1;
}
</style>
