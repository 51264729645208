<template>
  <transition name="modal">
    <div
      class="modal-mask"
      v-on:click="
        () => {
          if (clickOutSideModalHandler) {
            clickOutSideModalHandler();
          }
        }
      "
    >
      <div v-on:click.stop class="modal-container flex-container gc-modal-mt">
        <button
          class="button button-close gc-btn-close"
          v-if="showCancelButton"
          @click="$emit('close')"
        >
          <span >&times;</span>
        </button>
        <div class="modal-body custom-scrollbar">
          <slot></slot>
        </div>
        <div v-if="showButtons" class="modal-footer flex-container">
          <button
            v-if="showCancelButton"
            class="btn secondary"
            @click="$emit('close')"
          >
            {{ $t("cancel") }}
          </button>
          <button
            class="btn primary"
            :class="[{ 'with-margin-left': showCancelButton }]"
            @click="clickHandler"
          >
            {{ textButton }}
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: "Modal",
  components: {},
  props: {
    "click-handler": {
      type: Function,
      required: false,
      default: function () {},
    },
    "text-button": {
      type: String,
      required: false,
      default: "Ok",
    },
    clickOutSideModalHandler: {
      type: Function,
      required: false,
    },
    showCancelButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    modalType: {
      type: String,
      required: false,
      default: "",
    },
    showButtons: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {},
  methods: {},
};
</script>
<style lang="less" scoped>
.gc-modal-mt{
  margin-top: 84px !important;
  max-height: 80vh !important;
  // bottom: -54px;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: none;
  transition: opacity 0.3s ease;
  background-color: rgba(0, 0, 0, 0.3);
  margin-top: 24px
;
  
}

.modal-container {
  margin: auto;
  background-color: #fff;
  border-radius: 8px;
  transition: all 0.3s ease;
  font-family: "Roboto", sans-serif;
  // box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
    // 0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
  min-height: 120px;
  max-height: 100vh;
  // width: 100%;
  max-width: 70%;
  flex-direction: column;
  padding: 8px;
  background: #374ff9;
  box-shadow: -4px 4px 0px 0px rgba(0,0,0,0.25);
  position: relative;
  // height: 100%;

}
.modal-header {
  height: 28px;
  //   width: 280px;
  color: rgba(0, 0, 0, 0.6);
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 28px;
}
.modal-body {
  color: rgba(0, 0, 0, 0.6);
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 28px;
  min-height: 48px;
  // max-height: 79vh;
  width: 100%;
  // height: 100vh;
  overflow:hidden;
  // height: 100%;
}

.modal-footer {
  margin-top: 3px;
  align-self: flex-end;
  button {
    margin-left: auto;
  }
  button:nth-child(2) {
    margin-right: 8px;
  }

  .with-margin-left {
    margin-left: 8px;
  }

  .remove-pdf {
    margin-left: 8px;
    margin-right: 8px;
  }
}
.button {
  border: none;
  border-radius: 2px;
}
.button-close {
  background-color: transparent;
  color: #000;
  cursor: pointer;
  font-size: 44px;
  margin-bottom: -35px;
  margin-right: -7px;
  // margin-top: -10px;
  span {
    position: relative;
    z-index: 999999;
    float: right;
  }
}
.gc-btn-close {
  margin: 0;
  position: absolute;
  right: 3px;
  top: -6px;
  z-index: 1;
}
</style>
