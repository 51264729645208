<template>
  <div
    class="image-details-container"
    :style="{
      'max-width': `${captionWidth}px`,
      'margin-bottom': `${imageDetails.marginBottom}px`,
    }"
  >
    <span class="title" :style="{ width: `${captionWidth}px` }" v-if="title">{{
      title
    }}</span>
    <div
      class="image-details-image"
      :class="[{ 'shadowed-image': imageDetails.shadow }]"
    >
      <div class="img_detail" :class="{ 'm-bottom': imageDetails.shadow }">
        <img
          :src="imageUrl"
          :class="{ 'drop-shadow': imageDetails.shadow }"
          :style="{ 'max-width': `${maxWidth}px` }"
          v-on:load="onLoadImage"
        />
        <audio-toggle
          v-if="imageDetails.audio"
          :white-background="true"
          :audio="imageDetails.audio"
        />
      </div>
      <div class="caption-disclaimer">
        <span
          class="disclaimer"
          v-if="disclaimer"
          :style="{ 'margin-left': `${captionMargin}px` }"
          >{{ disclaimer }}</span
        >
        <div class="image-details-caption row mt-1" v-if="caption">
          <audio-toggle
            v-if="captionAudioName"
            class="audio-icon"
            :white-background="true"
            :audio="imageDetails.captionAudio"
          />
          <div
            :class="{ captionAudio: captionAudioName }"
            :style="captionStyles"
            class="image-caption-text"
            v-html="caption"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AudioToggle from "@/common/AudioToggle.vue";

export default {
  name: "ImageDetails",
  components: {
    AudioToggle,
  },
  props: {
    imageDetails: Object,
    defaultWidth: {
      type: Number,
      required: false,
      default: 1280,
    },
  },
  data() {
    return {
      captionWidth: this.maxWidth,
    };
  },
  computed: {
    title() {
      return this.imageDetails.title;
    },
    disclaimer() {
      return this.imageDetails.disclaimer;
    },
    captionAudioName() {
      return this.imageDetails.captionAudio
        ? this.locale === "en"
          ? this.imageDetails.captionAudio.nameEnglish
          : this.imageDetails.captionAudio.nameSpanish
        : null;
    },
    caption() {
      return this.imageDetails.caption
        ? this.imageDetails.caption[this.locale]
        : null;
    },
    captionMargin() {
      return this.imageDetails.captionMargin != ""
        ? this.imageDetails.captionMargin
        : 0;
    },
    imageUrl() {
      return this.getImageUrl(this.imageDetails.name);
    },
    captionStyles() {
      return {
        ...(this.captionAudioName == null && {
          "margin-left": `${this.captionMargin}px`,
        }),
      };
    },
  },
  methods: {
    onLoadImage({ path }) {
      let _imgElement = path[0];
      let _width = 300;

      if (_imgElement.width >= this.maxWidth) _width = this.maxWidth;
      else if (_imgElement.width > _width) _width = _imgElement.width;

      this.captionWidth = _width;
    },
  },
  created() {
    this.maxWidth = this.defaultWidth;
  },
};
</script>

<style lang="less" scoped>
.audio-icon {
  position: absolute;
  z-index: 1;
  margin-left: 12px;
}
.image-caption-text {
  margin-top: 4px;
  margin-bottom: 4px;
  width: 100%;
  margin-left: 12px;
  padding-left: 28px;
  padding-right: 15px;
  word-break: break-word;
}
.image-details-container {
  span {
    letter-spacing: 0.25px;
    // color: #0A1554;
    word-wrap: break-word !important;
    white-space: break-spaces !important;

    &.title {
      text-align: center;
      text-transform: uppercase;

      font-size: 24px;
      font-weight: bold;
      line-height: 24px;

      margin-bottom: 16px;
    }

    &.disclaimer {
      // color: #0A1554;
      font-family: "Roboto";
      font-size: 9px;
      font-style: italic;
      letter-spacing: 0.4px;
      line-height: 9px;
      white-space: pre-wrap !important;
      text-align: left;
      overflow: unset;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
  &:last-child {
    .image-details-image {
      margin-bottom: 0;
      &.shadowed-image {
        margin-bottom: 0;
      }
    }
  }
  .image-details-image {
    // margin-top: 8px !important;
    // margin: auto;
    height: auto;
    position: relative;
    margin-bottom: 15px;
    // .caption-disclaimer {
    //     margin-bottom: 15px;
    // }
    &.shadowed-image {
      margin-bottom: 0;
      // padding-left: 12px;

      margin-bottom: 30px;
    }

    .audio-toggle {
      position: absolute;
      right: 0;
      bottom: 5px;
    }
  }

  .image-details-caption {
    margin-top: 5px;
    align-content: center !important;

    .audio-toggle {
      margin-right: 10px;
    }

    & /deep/ p {
      margin: 0px;
    }
  }
}
.singleImageRow {
  .image-details-container {
    .image-details-image {
      .title {
        display: block;
      }
      .disclaimer {
        display: block;
      }
      .img_detail {
        position: relative;
        &.m-bottom {
          margin-bottom: 15px;
        }
      }
      .audio-toggle {
        &.white-background {
          bottom: 5px;
          right: 5px;
        }
      }
      .image-details-caption {
        margin-left: 0;
        margin-right: 0;
        .image-caption-text {
          width: calc(100% - 34px);
          padding-left: 0;
          margin-left: 0 !important;
        }
        .audio-toggle {
          margin: 0 5px 0 0;
          &.white-background {
            bottom: 0;
            right: 0;
            left: 0;
            position: relative;
          }
        }
      }
    }
  }
}
.cardContentImage {
  .title {
    display: block;
    text-align: left;
  }
  .img_detail {
    position: relative;
    &.m-bottom {
      margin-bottom: 15px;
    }
    .audio-toggle {
      right: 5px;
      bottom: 5px;
    }
  }
  .image-details-image {
    &.shadowed-image {
      margin-bottom: 0;
    }
    .disclaimer {
      display: block;
    }
  }
  .image-details-caption {
    margin: 0;
    position: relative;
    justify-content: left !important;
    .image-caption-text {
      width: 100%;
      // display: inline-block;
      padding-left: 0;
      margin-left: 0 !important;
      &.captionAudio {
        width: calc(100% - 34px);
      }
    }
    .audio-toggle {
      position: relative;
      left: 0;
      margin-left: 0;
      bottom: 0;
    }
  }
}
.singleImageRow {
  .image-details-container {
    margin-right: 0;
    .image-details-image {
      &.shadowed-image {
        padding-left: 12px;
      }
    }
  }
}
</style>
