var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lessonLayoutRow", style: { order: _vm.content.order } },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            staticClass: "lessonLeftColumn col-md-5 col-sm-5 col-xs-5",
            class: _vm.content.imagesOrientation
          },
          [
            _c(
              "div",
              { ref: "leftcol" },
              _vm._l(_vm.images, function(image, index) {
                return _c("image-details", {
                  key: index,
                  staticClass: "image-details-wrapper",
                  attrs: {
                    imageDetails: image,
                    "default-width": _vm.imageDetailsWidth
                  }
                })
              })
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass: "lessonRightColumn col-md-7 col-sm-7 col-xs-7",
            style: { "justify-content": _vm.content.alignment }
          },
          [
            _vm.essentialQuestionText
              ? _c("essential-question", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: false,
                      expression: "false"
                    }
                  ],
                  key: _vm.content.essentialQuestion.id,
                  attrs: {
                    audio: _vm.essentialQuestionAudio,
                    message: _vm.essentialQuestionText
                  }
                })
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "body",
                class: _vm.content.imagesOrientation,
                style: { minHeight: "176px" }
              },
              [
                _c("div", { class: { audio: _vm.content.audio } }, [
                  _c("span", {
                    domProps: { innerHTML: _vm._s(_vm.textContent) },
                    on: { click: _vm.GoToLink }
                  })
                ]),
                _vm.content.audio
                  ? _c("AudioToggle", { attrs: { audio: _vm.content.audio } })
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }