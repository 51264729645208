<template>
    <div class="essential-question row ml-0 mr-0">
      <img class="brain-image" v-if="brainIcon" :src="brainIcon" @click="isBrainClicked = !isBrainClicked">
      <span >{{info}}</span>
      <AudioToggle v-if="audio && isBrainClicked" :audio="audio"/>
    </div>
</template>
<script>
import AudioToggle from '@/common/AudioToggle.vue'
export default {
    name: 'EssentialQuestion', 
    components:{
        AudioToggle
    },
    props:{
        message: {
            type: Object,
            required: true
            }, 
        audio: {
            type: Object,
            required: false
        }
    },
    data: function(){
        return{
            isBrainClicked : false
        }
    },
    watch: { 
        message: function() {
            this.isBrainClicked = false;
        }
    },
    computed: {
        info(){
            return this.isBrainClicked ? this.message[this.locale] : this.$t('essentialQuestionLegend')
        }, 
        brainIcon(){
            return require('@/assets/icons/Brain.gif')
        }
  }
}
</script>
<style lang="less" scoped>

.essential-question{
    min-height:  48px;
    height: auto;
    background-color: #EAEBFF;
    z-index: 2;
    align-items: center;
    img, .audio-toggle{
        cursor: pointer;
        align-self: center;
    }

    img:first-child{
      height: 29.59px;	
      width: 32px;
      margin-left: 16px;
    }

    .audio-toggle{
        margin-left: auto;
        margin-right: 24px;
    }

    span:nth-child(2){
        height: 16px;
        width: auto;
        max-width: 415.1px;
        color: #3A4DFD;
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-style: italic;
        letter-spacing: 0.4px;
        line-height: 20px;
        margin-left: 24px;
        margin-right: 24px;
    }
}

</style>

