var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "disclaimer row" }, [
    _c(
      "span",
      {
        directives: [
          {
            name: "tooltip",
            rawName: "v-tooltip",
            value: _vm.disclaimer,
            expression: "disclaimer"
          }
        ]
      },
      [_vm._v(_vm._s(_vm.disclaimer))]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }