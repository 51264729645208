var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "image-details-container",
      style: {
        "max-width": _vm.captionWidth + "px",
        "margin-bottom": _vm.imageDetails.marginBottom + "px"
      }
    },
    [
      _vm.title
        ? _c(
            "span",
            { staticClass: "title", style: { width: _vm.captionWidth + "px" } },
            [_vm._v(_vm._s(_vm.title))]
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "image-details-image",
          class: [{ "shadowed-image": _vm.imageDetails.shadow }]
        },
        [
          _c(
            "div",
            {
              staticClass: "img_detail",
              class: { "m-bottom": _vm.imageDetails.shadow }
            },
            [
              _c("img", {
                class: { "drop-shadow": _vm.imageDetails.shadow },
                style: { "max-width": _vm.maxWidth + "px" },
                attrs: { src: _vm.imageUrl },
                on: { load: _vm.onLoadImage }
              }),
              _vm.imageDetails.audio
                ? _c("audio-toggle", {
                    attrs: {
                      "white-background": true,
                      audio: _vm.imageDetails.audio
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c("div", { staticClass: "caption-disclaimer" }, [
            _vm.disclaimer
              ? _c(
                  "span",
                  {
                    staticClass: "disclaimer",
                    style: { "margin-left": _vm.captionMargin + "px" }
                  },
                  [_vm._v(_vm._s(_vm.disclaimer))]
                )
              : _vm._e(),
            _vm.caption
              ? _c(
                  "div",
                  { staticClass: "image-details-caption row mt-1" },
                  [
                    _vm.captionAudioName
                      ? _c("audio-toggle", {
                          staticClass: "audio-icon",
                          attrs: {
                            "white-background": true,
                            audio: _vm.imageDetails.captionAudio
                          }
                        })
                      : _vm._e(),
                    _c("div", {
                      staticClass: "image-caption-text",
                      class: { captionAudio: _vm.captionAudioName },
                      style: _vm.captionStyles,
                      domProps: { innerHTML: _vm._s(_vm.caption) }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }