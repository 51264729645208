<template>
    <div class="video-content" :style="{order : content.order}" >
        <video-player :id="content.id" :url="videoUrl" :thumbnail="thumbnail"
         :width="700" :height="394" />
    </div>
</template>
<script>
import ParagraphMixin from './ParagraphMixin.js'
import VideoPlayer from '@/common/VideoPlayer.vue'
export default {
  name: 'VideoContent',
  mixins:[ParagraphMixin],
  components:{
      VideoPlayer
  },
  computed:{
    videoUrl(){
        return this.content.videoUrl
    }, 
    thumbnail(){
        let image = this.content.images[0] 
        return image ? image.name : ''
    }
  }, 
}
</script>

<style lang="less">
.wrap-lesson {
    .video-content{
        width: 100% !important;
        margin-top: 0 !important;
        margin-bottom: 30px !important;
    //    padding: 10px;
        text-align: center;
        .video-player{
            margin: auto;
        }
    }
}
</style>