<template>
    <div class="card cardContentImage" :style="{order : content.order}">
        <div class="row">
            <div class="col-md-6 col-sm-6 col-xs-6 " :class="content.imagesOrientation">
                <div ref="leftcol">
                <image-details v-for="(image, index) in images" 
                    :key="index" 
                    :imageDetails="image"
                    :default-width="304"/>
                </div>
            </div>
            <div class="column col-md-6 col-sm-6 col-xs-6" :style="{'align-self': content.alignment,}">
                <div class="card-header row" ref="cardHeader">
                    <span class="card-title" :class="{'titleAudio': content.audio}">{{content.title}}</span>
                    <AudioToggle v-if="content.audio"
                    :white-background="true"
                    :audio="content.audio"/>
                </div>
                <div class="body" :style="{height: maxHeightContent + 'px'}">
                    <div class="card-overflow h-100">
                    <span  @click="GoToLink" v-html="textContent" ></span>
                    </div> 
                </div>
            </div>
            <modal v-if="showModal" :text-button="$t('done')" 
                :click-handler="hideVocabularyBox" 
                :clickOutSideModalHandler="hideVocabularyBox"
                :showCancelButton="showModalCancelButton"
                :modalType="modalType"> <vocabulary-box :vocabulary="selectedWord" /></modal>
        </div>
        
    </div>
    <!-- <div class="card row cardContentImage" :style="{order : content.order}">
        <section class="column" :class="content.imagesOrientation">
            <image-details v-for="(image, index) in images" 
                            :key="index" :imageDetails="image"
                            :default-width="304"/>
        </section>
        <div class="column " :style="{'align-self': content.alignment}">
            <div class="card-header row">
                <span class="card-title" >{{content.title}}</span>
                <AudioToggle v-if="content.audio"
                :white-background="true"
                 :audio="content.audio"/>
            </div>
            <div class="body">
                <div>
                 <span  @click="GoToLink" v-html="textContent" ></span>
                </div> 
            </div>
        </div>
        <modal v-if="showModal" :text-button="$t('done')" 
            :click-handler="hideVocabularyBox" 
            :clickOutSideModalHandler="hideVocabularyBox"
            :showCancelButton="showModalCancelButton"
            :modalType="modalType"> <vocabulary-box :vocabulary="selectedWord" /></modal>
    </div> -->
</template>

<script>
import ParagraphMixin from './ParagraphMixin.js'
export default {
  name: 'LessonCard',
  mixins:[ParagraphMixin],
  data(){
    return{
      maxheight: null,
      maxHeightContent:null
    }
  },
  methods:{
    getLeftColumnHeight(){
      var fullHeight = this.$refs.leftcol.clientHeight;
      var headerTitleHeight = this.$refs.cardHeader.clientHeight;
      this.maxheight = fullHeight;
      this.maxHeightContent = fullHeight - headerTitleHeight
    }
  },
  mounted(){
    //   setInterval(() => {
    //   this.getLeftColumnHeight();
    // }, 1000);
  },
  updated(){
      setTimeout(()=>{
           this.getLeftColumnHeight();
      },1000)
  }

}
</script>

<style lang="less">
.wrap-lesson {
.card{
    width: 100% !important;
    margin-top: 0 !important;
    &.cardContentImage {
        margin-bottom: 30px;
        .image-details-container {
            max-width: 100%;
            .title {
                white-space: initial;
            }
        }
    }
    img{
        height: auto;
        // max-width: 304px;
        border-radius: 10px;
        margin: auto;
    }
    .column:first-child{
        margin-left: auto;
    }
    .column:nth-child(2){
        margin-right: auto;
    }

    .card-title{
        width: 100%;
        white-space: normal;
        height: auto;
        color: #fff;
        font-size: 19.64px;
        font-weight: bold;
        letter-spacing: 0.25px;
        line-height: 24px;
        margin: auto;
        padding: 8px 5px;
        padding-left: 13px;
        &.titleAudio {
            width: 93%;
            margin-left: 0;
        }
    }

    .column:nth-child(2) .card-header{
        min-height: 56px;
        width: 100%;
        background-color: #0A1554;
        position: relative;
        align-self: center;
        .audio-toggle{
            position: relative;
            top: 16px;
            right: 20px;
            // transform: translate(0, -50%);
        }
    }
    p{
        font-size: 16px !important;
        letter-spacing: 0.5px !important;
        line-height: 24px !important;
    }

    .body{
        background: #fff;
        width: 100%;
        max-width: 100%;
        padding: 15px;
        -webkit-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.2);
                box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.2);
        div {
            margin: 0 !important;
            min-width: 100% !important;
            span {
                p {
                    margin-top: 0;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
}
.card-overflow{
    overflow: auto;
}

</style>